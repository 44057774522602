<template>
  <div>
    <div>
      <div
        class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap mb-3"
      >
        <el-select
          class="select-primary pagination-select"
          v-model="pagination.perPage"
          :placeholder="$t('COMMON.PER_PAGE')"
        >
          <el-option
            class="select-primary"
            v-for="item in pagination.perPageOptions"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>

        <el-select :placeholder="$t('COMMON.READ')" v-model="isRead">
          <el-option :value="null" :label="$t('NOTIFICATIONS.ALL')">
          </el-option>
          <el-option
            :value="true"
            :label="$t('NOTIFICATIONS.READ')"
          ></el-option>
          <el-option
            :value="false"
            :label="$t('NOTIFICATIONS.UNREAD')"
          ></el-option>
        </el-select>

        <div v-if="selectedNotifications.length > 0">
          <base-button type="info" @click="markAsRead">
            {{ $t("NOTIFICATIONS.MARK_AS_READ") }}
          </base-button>
          <base-button type="info" @click="markAsUnRead">
            {{ $t("NOTIFICATIONS.MARK_AS_UNREAD") }}
          </base-button>
        </div>
      </div>

      <el-table
        class="table-responsive align-items-center table-flush"
        header-row-class-name="thead-light"
        :data="notifications"
        @sort-change="sortChange"
        @selection-change="handleSelectionChange"
        :row-class-name="tableRowClassName"
      >
        <div slot="empty" v-if="loading">
          <img src="/img/loading.gif" style="height: 100px; width: 100px" />
        </div>
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column>
          <template v-slot="{ row }">
            {{ getNotificationDuration(row) }}<br />
            <span class="text-muted">
              {{ row.created_at | moment("LLLL") }}
            </span>
          </template>
        </el-table-column>
        <el-table-column>
          <template v-slot="{ row }">
            {{ `${getNotificationObject(row)} - ${row.data.object_name}` }}
          </template>
        </el-table-column>
        <el-table-column>
          <template v-slot="{ row }">
            <p v-html="getNotificationDescription(row)"></p>
          </template>
        </el-table-column>

        <el-table-column min-width="50px" align="center">
          <div slot-scope="{ row }" class="table-actions">
            <el-tooltip :content="$t('COMMON.VIEW')" placement="top">
              <a
                type="text"
                @click="readNotification(row)"
                class="table-action"
                data-toggle="tooltip"
                style="cursor: pointer"
              >
                <i class="fas fa-eye"></i>
              </a>
            </el-tooltip>
          </div>
        </el-table-column>
      </el-table>
    </div>
    <div
      slot="footer"
      class="mt-5 col-12 d-flex justify-content-center justify-content-sm-between flex-wrap table-footer"
    >
      <div class="">
        <p class="card-category">
          {{
            $t("COMMON.DISPLAY_FROM_X_TO_X_OF_X_ENTRIES", {
              from: total ? from + 1 : 0,
              to: to,
              of: total,
            })
          }}
          <span v-if="selectedRows.length">
            &nbsp; &nbsp;
            {{ $t("COMMON.X_LINES_SELECTED", { count: selectedRows.length }) }}
          </span>
        </p>
      </div>
      <base-pagination
        class="pagination-no-border"
        v-model="pagination.currentPage"
        :per-page="pagination.perPage"
        :total="total"
      />
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import swal from "sweetalert2";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import "flatpickr/dist/flatpickr.css";
import { BasePagination } from "@/components";

export default {
  name: "notification-list-tabke",

  components: {
    BasePagination,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  mixins: [],

  props: {
    filterSubject: {
      type: Object,
      default: null,
      description: "Subject of notification",
    },
  },

  data() {
    return {
      query: null,
      selectedRows: [],
      sort: "-created_at",
      total: 0,
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [20, 50, 100, 500],
      },
      notifications: [],
      loading: true,
      isRead: null,
      selectedNotifications: [],
    };
  },
  computed: {
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
  },
  watch: {
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
    selectedObjectType: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedObjectId: {
      handler: "getListDebounced",
      immediate: true,
    },
    isRead: {
      handler: "getListDebounced",
      immediate: true,
    },
  },

  methods: {
    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    async getList() {
      try {
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {},
          page: {
            number: this.pagination.currentPage,
            size: this.pagination.perPage,
          },
          include: "",
        };

        if (this.isRead !== null) {
          if (this.isRead) {
            params = {
              ...params,
              filter: {
                ...params.filter,
                read: true,
              },
            };
          } else {
            params = {
              ...params,
              filter: {
                ...params.filter,
                unRead: true,
              },
            };
          }
        }

        await this.$store.dispatch("notifications/list", params);
        this.notifications = this.$store.getters["notifications/list"];
        this.total = this.$store.getters["notifications/listTotal"];
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    sortChange({ prop, order }) {
      if (order === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      }
      this.getList();
    },

    getNotificationDuration(notification) {
      const duration = this.$moment
        .duration(this.$moment().diff(this.$moment(notification.created_at)))
        .humanize();

      return this.$t("NOTIFICATIONS.X_AGO", {
        duration: duration,
      });
    },

    getNotificationObject(notification) {
      const model = notification.data.object_class.split("\\").pop();
      return this.$t(`COMMON.${model.toUpperCase()}`);
    },

    getNotificationDescription(notification) {
      const causer = `${notification.data.causer.firstname} ${notification.data.causer.lastname}`;
      const event = this.$t(
        `NOTIFICATIONS.DESCRIPTION_EVENT_${notification.data.event}`
      );
      const objectName = notification.data.object_name;

      return this.$t("NOTIFICATIONS.XCAUSER_HAS_XEVENT_XOBJECTNAME", {
        causer: causer,
        event: event,
        objectName: objectName,
      });
    },

    async readNotification(notification) {
      swal.fire({
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading();
        },
      });
      try {
        await this.$store.dispatch("notifications/get", notification.id);
        this.$router.push(
          this.$objectViewRoute({
            type: notification.data.object_type,
            id: notification.data.object_id,
          })
        );
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
      swal.close();
    },

    async markAsRead(notification) {
      swal.fire({
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading();
        },
      });
      try {
        await this.$store.dispatch(
          "notifications/markAsRead",
          this.selectedNotifications.map((item) => item.id)
        );
        await this.getList();
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
      swal.close();
    },

    async markAsUnRead(notification) {
      swal.fire({
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading();
        },
      });
      try {
        await this.$store.dispatch(
          "notifications/markAsUnRead",
          this.selectedNotifications.map((item) => item.id)
        );
        await this.getList();
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
      swal.close();
    },

    tableRowClassName({ row, rowIndex }) {
      if (!row.read_at) {
        return "bold";
      }
      return "";
    },

    handleSelectionChange(data) {
      this.selectedNotifications = data;
    },
  },
};
</script>

<style>
tr.el-table__row.bold .cell,
tr.el-table__row.bold .cell p {
  font-weight: bolder;
  font-size: 1rem;
}
</style>