<template>
  <div class="container-fluid mt-5">
    <div>
      <card
        class="no-border-card"
        body-classes="px-0 pb-1"
        footer-classes="pb-2"
      >
        <template slot="header">
          <div class="row">
            <div class="col-6">
              <h3 class="mb-0">{{ $t("NOTIFICATIONS.NOTIFICATIONS_LIST") }}</h3>
            </div>
            <div class="col-6 text-right"></div>
          </div>
        </template>

        <notification-list-table />
      </card>
    </div>
  </div>
</template>
<script>
import { Button } from "element-ui";
import NotificationListTable from "./partials/NotificationListTable.vue";

export default {
  layout: "DashboardLayout",

  components: {
    NotificationListTable,
    [Button.name]: Button,
  },

  mixins: [],

  computed: {},

  watch: {},

  methods: {},
};
</script>
