var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('div',{staticClass:"col-12 d-flex justify-content-center justify-content-sm-between flex-wrap mb-3"},[_c('el-select',{staticClass:"select-primary pagination-select",attrs:{"placeholder":_vm.$t('COMMON.PER_PAGE')},model:{value:(_vm.pagination.perPage),callback:function ($$v) {_vm.$set(_vm.pagination, "perPage", $$v)},expression:"pagination.perPage"}},_vm._l((_vm.pagination.perPageOptions),function(item){return _c('el-option',{key:item,staticClass:"select-primary",attrs:{"label":item,"value":item}})}),1),_c('el-select',{attrs:{"placeholder":_vm.$t('COMMON.READ')},model:{value:(_vm.isRead),callback:function ($$v) {_vm.isRead=$$v},expression:"isRead"}},[_c('el-option',{attrs:{"value":null,"label":_vm.$t('NOTIFICATIONS.ALL')}}),_c('el-option',{attrs:{"value":true,"label":_vm.$t('NOTIFICATIONS.READ')}}),_c('el-option',{attrs:{"value":false,"label":_vm.$t('NOTIFICATIONS.UNREAD')}})],1),(_vm.selectedNotifications.length > 0)?_c('div',[_c('base-button',{attrs:{"type":"info"},on:{"click":_vm.markAsRead}},[_vm._v(" "+_vm._s(_vm.$t("NOTIFICATIONS.MARK_AS_READ"))+" ")]),_c('base-button',{attrs:{"type":"info"},on:{"click":_vm.markAsUnRead}},[_vm._v(" "+_vm._s(_vm.$t("NOTIFICATIONS.MARK_AS_UNREAD"))+" ")])],1):_vm._e()],1),_c('el-table',{staticClass:"table-responsive align-items-center table-flush",attrs:{"header-row-class-name":"thead-light","data":_vm.notifications,"row-class-name":_vm.tableRowClassName},on:{"sort-change":_vm.sortChange,"selection-change":_vm.handleSelectionChange}},[(_vm.loading)?_c('div',{attrs:{"slot":"empty"},slot:"empty"},[_c('img',{staticStyle:{"height":"100px","width":"100px"},attrs:{"src":"/img/loading.gif"}})]):_vm._e(),_c('el-table-column',{attrs:{"type":"selection","width":"55"}}),_c('el-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.getNotificationDuration(row))),_c('br'),_c('span',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(_vm._f("moment")(row.created_at,"LLLL"))+" ")])]}}])}),_c('el-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(((_vm.getNotificationObject(row)) + " - " + (row.data.object_name)))+" ")]}}])}),_c('el-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',{domProps:{"innerHTML":_vm._s(_vm.getNotificationDescription(row))}})]}}])}),_c('el-table-column',{attrs:{"min-width":"50px","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{staticClass:"table-actions"},[_c('el-tooltip',{attrs:{"content":_vm.$t('COMMON.VIEW'),"placement":"top"}},[_c('a',{staticClass:"table-action",staticStyle:{"cursor":"pointer"},attrs:{"type":"text","data-toggle":"tooltip"},on:{"click":function($event){return _vm.readNotification(row)}}},[_c('i',{staticClass:"fas fa-eye"})])])],1)}}])})],1)],1),_c('div',{staticClass:"mt-5 col-12 d-flex justify-content-center justify-content-sm-between flex-wrap table-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('div',{},[_c('p',{staticClass:"card-category"},[_vm._v(" "+_vm._s(_vm.$t("COMMON.DISPLAY_FROM_X_TO_X_OF_X_ENTRIES", { from: _vm.total ? _vm.from + 1 : 0, to: _vm.to, of: _vm.total, }))+" "),(_vm.selectedRows.length)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("COMMON.X_LINES_SELECTED", { count: _vm.selectedRows.length }))+" ")]):_vm._e()])]),_c('base-pagination',{staticClass:"pagination-no-border",attrs:{"per-page":_vm.pagination.perPage,"total":_vm.total},model:{value:(_vm.pagination.currentPage),callback:function ($$v) {_vm.$set(_vm.pagination, "currentPage", $$v)},expression:"pagination.currentPage"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }